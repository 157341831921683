exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-about-page-tsx": () => import("./../../../src/templates/about/about-page.tsx" /* webpackChunkName: "component---src-templates-about-about-page-tsx" */),
  "component---src-templates-companies-faq-companies-faq-page-tsx": () => import("./../../../src/templates/companies-faq/companies-faq-page.tsx" /* webpackChunkName: "component---src-templates-companies-faq-companies-faq-page-tsx" */),
  "component---src-templates-company-company-page-tsx": () => import("./../../../src/templates/company/company-page.tsx" /* webpackChunkName: "component---src-templates-company-company-page-tsx" */),
  "component---src-templates-contact-contact-page-tsx": () => import("./../../../src/templates/contact/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-contact-page-tsx" */),
  "component---src-templates-faq-faq-page-tsx": () => import("./../../../src/templates/faq/faq-page.tsx" /* webpackChunkName: "component---src-templates-faq-faq-page-tsx" */),
  "component---src-templates-index-index-page-tsx": () => import("./../../../src/templates/index/index-page.tsx" /* webpackChunkName: "component---src-templates-index-index-page-tsx" */),
  "component---src-templates-terms-and-condition-terms-and-condition-page-tsx": () => import("./../../../src/templates/terms-and-condition/terms-and-condition-page.tsx" /* webpackChunkName: "component---src-templates-terms-and-condition-terms-and-condition-page-tsx" */),
  "component---src-templates-worker-worker-page-tsx": () => import("./../../../src/templates/worker/worker-page.tsx" /* webpackChunkName: "component---src-templates-worker-worker-page-tsx" */)
}

