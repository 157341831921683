import { GlobalState } from "./globalStore.reducer";
import { GlobalDispatch } from "./globalStore";
import { SupportedLanguage, translations } from "../../utils/translations";

const setLang =
  (dispatch: GlobalDispatch) => (lang: SupportedLanguage) => {
    dispatch({
      type: "SET_LANGUAGE",
      payload: { lang },
    });
  };

const getTranslation =
  (dispatch: GlobalDispatch, state: GlobalState) =>
  (key: keyof typeof translations['en'] ) => {
    if(!state.lang) return ''
    return translations[state.lang][key] || ''
  };

export const globalActions = (
  dispatch: GlobalDispatch,
  state: GlobalState
) => ({
  setLang: setLang(dispatch),
  getTranslation: getTranslation(dispatch, state)
});
