const React = require("react");
require("./static/fonts/fonts.css");

const {
  Providers,
  stores,
} = require("./src/stores/stores");

const SetLanguage = (props) => {
  const [state, actions] = stores.useGlobalStore()
  React.useEffect(() => {
    const pageLanguage = props.pageContext.lang
    if (pageLanguage !== state.lang) {
      actions.setLang(pageLanguage)
    }
  }, [actions, state.lang, props.pageContext.lang])
  return <>{props.children}</>
}
// Scrolling page to the top
exports.shouldUpdateScroll = ({ routerProps }) => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; 
}

exports.wrapPageElement = ({ element, props }) => {
  return (
    <Providers.GlobalStoreProvider><SetLanguage {...props}>{element}</SetLanguage></Providers.GlobalStoreProvider>
  );
};
