export enum SupportedLanguage {
  en = "en",
  sv = "sv",
}

export enum SupportedLanguageLongName {
  en = "English",
  sv = "Swedish",
}

const en = {
  worker: "Worker",
  company: "Company",
  privatePerson: "Private person",
  ContactFormTitle: "I am contacting you as",
  aboutUs: "About us",
  contact: "Contact",
  terms: "Terms and conditions",
  joinUs: "Follow us",
  contactUs: "Get in touch with us",
  message: "Message",
  email: "Email",
  subject: "Subject",
  name: "Name",
  send: "Send",
  downloadTheApp: "Download the app",
  sendEmailError: "Opps something went wrong when we tried to send the email.",
  emailSentTitle: "Thank you for your message!",
  emailSentText: "We will be in touch soon.",
  search: "Search",
  loadMore: "Show more",
  faq: "FAQ",
  allFaq: "All",
  Arbetare: "Worker",
  Företag: "Company",
  Betalning: "Payments",
  Övrigt: "Other",
};

const sv: typeof en = {
  worker: "Arbetare",
  company: "Företag",
  ContactFormTitle: "Jag kontaktar er som",
  privatePerson: "Privatperson",
  aboutUs: "Om oss",
  contact: "Kontakt",
  terms: "Allmänna villkor",
  joinUs: "Följ oss",
  contactUs: "Kom i Kontakt med oss",
  message: "Meddelande",
  email: "Email",
  subject: "Ämne",
  name: "Namn",
  send: "Skicka",
  downloadTheApp: "Hämta appen",
  sendEmailError: "Oj något gick fel när vi försökte skicka mejlet.",
  emailSentTitle: "Tack för ditt meddelande!",
  emailSentText: "Vi kommer höra av oss snart.",
  search: "Sök",
  loadMore: "Se mer",
  faq: "Frågor och svar",
  allFaq: "Alla frågor",
  Arbetare: "Arbetare",
  Företag: "Företag",
  Betalning: "Betalning",
  Övrigt: "Övrigt",
};

export const translations = {
  en,
  sv,
};
