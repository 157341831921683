import React, { createContext, useReducer, useContext, useEffect } from "react";
import { globalActions } from "./globalStore.actions";
import {
  GlobalActions,
  globalInitState,
  GlobalState,
  globalStateReducer,
} from "./globalStore.reducer";

export type GlobalDispatch = (action: GlobalActions) => void;

const GlobalStateContext = createContext<GlobalState | undefined>(undefined);
const GlobalDispatchContext = createContext<GlobalDispatch | undefined>(
  undefined
);

export const GlobalStoreProvider: React.FC = ({ children }) => {
  const getInitState = () => {
    if (typeof window === "undefined") return globalInitState;
    const presistedState = localStorage.getItem("PERSISTED::GlobalState");
    // If persisted state exist we will update the store with those values.
    if (presistedState) {
      const cachedState: GlobalState = JSON.parse(presistedState);
      return cachedState
    } else return globalInitState
  }

  const [state, dispatch] = useReducer(globalStateReducer, getInitState());

  // useEffect(() => {
  //   if (typeof window === "undefined") return;
  //   const presistedState = localStorage.getItem("PERSISTED::GlobalState");
  //   // If persisted state exist we will update the store with those values.
  //   if (presistedState && JSON.stringify(presistedState) === JSON.stringify(state)) {
  //     const cachedState: GlobalState = JSON.parse(presistedState);
  //     console.log('cachedState', cachedState)
  //     dispatch({
  //       type: "SET_CACHED_STATE",
  //       payload: cachedState,
  //     });
  //   }
  // }, []);

  // Sync state changes to LocalStorage
  useEffect(() => {
    if (typeof window === "undefined") return;
    localStorage.setItem(
      "PERSISTED::GlobalState",
      JSON.stringify(state)
    );
  }, [state]);
  
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const useGlobalStore = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  if (state === undefined || dispatch === undefined) {
    console.log('state',state)
    console.log('dispatch',dispatch)
    throw new Error("useGlobalStore must be used within a GlobalStoreProvider");
  }

  const actions = globalActions(dispatch, state);
  return [state, actions, dispatch] as const;
};
