import { SupportedLanguage } from "../../utils/translations";

export type GlobalActions = {
  type: "SET_LANGUAGE";
  payload: { lang: SupportedLanguage };
} | {
  type: "SET_CACHED_STATE";
  payload: GlobalState;
};

export type GlobalState = {
  lang: SupportedLanguage | null;
};

export const globalInitState: GlobalState = {
  lang: null,
};

export const globalStateReducer = (
  state: GlobalState,
  action: GlobalActions
): GlobalState => {
  switch (action.type) {
    case "SET_LANGUAGE": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "SET_CACHED_STATE": {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};
