import {
  GlobalStoreProvider,
  useGlobalStore,
} from "./globalStore/globalStore";

export const Providers = {
  GlobalStoreProvider,
};

export const stores = {
  useGlobalStore,
};
